import Bowser from 'bowser';

function getDeviceInfo() {
  return Bowser.parse(window.navigator.userAgent);
}

function getBrowserInfo() {
  return Bowser.getParser(window.navigator.userAgent).getBrowser();
}

export { getDeviceInfo, getBrowserInfo };
