function useAmounts() {
  let locale = null;
  let device = null;
  return {
    setLocale: value => {
      locale = value;
    },
    getLocale: () => {
      return locale;
    },
    setDevice: value => {
      device = value;
    },
    getDevice: () => {
      return device;
    }
  };
}

function Queue(initial) {
  const items = initial;
  return {
    coming: item => {
      items.shift(item);
    },
    going: () => {
      return items.pop();
    }
  };
}

const { setDevice, setLocale, getDevice, getLocale } = useAmounts();

//TODO: added endpoint for send data to server
//TODO: think about "InTheMoment" "InTheSequence"
function setup() {
  window.addEventListener('error', error => {
    const date = new Date();
    const device = getDevice();
    const locale = getLocale();
    //TODO: appversion
    const errorInfo = {
      locale,
      // device
      browserName: device && device.browser.name,
      browserVersion: device && device.browser.version,
      osName: device && device.os.name,
      osVersion: device && device.os.version,
      osVersionName: device && device.os.versionName,
      platformType: device && device.platform.type,
      platformVendor: device && device.platform.vendor,
      // date
      timeStamp: date.getTime(),
      date: date.toString(),
      // error info
      colno: error.colno,
      lineno: error.lineno,
      errorMessage: error.error.message,
      errorStack: error.error.stack,
      filename: error.filename,
      // language
      language: error.currentTarget.clientInformation.language,
      languages: error.currentTarget.clientInformation.languages,
      // online or offlive
      onLine: error.currentTarget.clientInformation.onLine,
      // history of route
      numberOfTrips: error.currentTarget.history.length,
      // size
      innerHeight: error.currentTarget.innerHeight,
      innerWidth: error.currentTarget.innerWidth,
      width: error.currentTarget.screen.width,
      height: error.currentTarget.screen.height,
      // location
      protocol: error.currentTarget.location.protocol,
      origin: error.currentTarget.location.origin,
      href: error.currentTarget.location.href,
      search: error.currentTarget.location.search
    };

    console.error(errorInfo);
  });
}

export { setup, setLocale, setDevice };
