import {
  setup as setupLogging,
  setDevice as setLoggingDevice
} from "modules/logging";
import { getBrowserInfo, getDeviceInfo } from "modules/bowser";

/**
 * support browser
 */
const supportBrowsersList = {
  "Internet Explorer": {
    version: 11
  },
  "Microsoft Edge": {
    version: 14
  },
  Chrome: {
    version: 49
  },
  Firefox: {
    version: 52
  },
  Safari: {
    version: 10
  },
  Googlebot: {
    version: 0
  }
};

async function start() {
  /**
   * setup logging for trace error data
   */
  setupLogging();

  /**
   * Logging module is light, and we need
   * initial lazy method to set data everytime
   * and logging module should haven't dependencies
   */
  setLoggingDevice(getDeviceInfo());

  const currentBrowser = getBrowserInfo();

  if (
    supportBrowsersList[currentBrowser.name] === undefined ||
    parseInt(currentBrowser.version) >=
      supportBrowsersList[currentBrowser.name].version
  ) {
    const { default: boot } = await import("./boot");
    boot();
  } else {
    //TODO: need alert to user for update browser or use another new browser
    console.log("Brower not support");
  }
}

start();
